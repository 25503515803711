import { createContext } from 'react';
import { defineAbility } from '@casl/ability';

export const PermissionsAbility = defineAbility((can, cannot) => {
  cannot('owner');
  cannot('readMultipleProjects');
  cannot('read', 'twfx');
  cannot('read', 'mcfx');
  cannot('read', 'cgfx');
  cannot('read', 'chatfx"');
  cannot('read', 'nutshell');
  cannot('read', 'opsfx"');
  can('read', 'isDefaults');
});

export const PermissionsContext = createContext();
